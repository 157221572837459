.container {
  background-color: #26292e;
  color: #2e2f30;
  font-family: sans-serif;
  margin: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.dialog {
  float: right;
  text-align: left;
  width: 60%;
  margin: 5% auto 0;
  margin-top: 150px;
  padding-right: 100px;
}

.dialog h1 {
  font-size: 4em;
  color: #fff;
  line-height: 1em;
}

.dialog p {
  font-size: 1.4em;
  color: #fff;
  padding-right: 5%;
}
.image {
  position: absolute;
  float: left;
  top: 0;
  width: 40%;
}
@media only screen and (max-width: 767px) {
  .image {
    width: 100%;
    top: -50px;
  }
  .dialog {
    float: none;
    text-align: center;
    width: 90%;
    margin-top: 70%;
  }
}
