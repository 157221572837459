#home_jumbo:after {
  background: url("./assets/jumbo.webp") no-repeat center center;
  filter: brightness(40%);
  background-size: cover;
}

.grid {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
  width: 100%;
  display: grid;
  /* Define Auto Row size */
  /* grid-auto-rows: 100px; */
  /*Define our columns */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1em;
}

.grid_item {
  border-radius: 10px;
  padding: 20px;
  color: black;
  display: flex;
  align-items: center;
  background-color: whitesmoke;
}
.grid_item > svg {
  min-width: 40px;
}
.icon {
  margin-right: 20px;
  width: 20%;
}
.header_text {
  text-align: center;
}

.help_alert {
  padding: 5px;
  margin-bottom: 0;
  text-align: left;
}
.help_alert p {
  margin: 0;
}
.help_alert a {
  color: black;
}
.services_link {
  text-align: center;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 701px) {
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  .card {
    background-color: rgb(20, 20, 20);
    text-align: center;
    border-radius: 5px;
    padding: 15px;
  }
  .card > h3 {
    padding: 25px;
  }
  .swiper_container {
    display: flex;
    justify-content: center;
  }
  .swiper_card {
    background-color: rgb(10, 10, 10);
    padding: 25px;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
  }
  .icon_container_card {
    padding: 25px;
  }
  .call_container {
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .call_button {
    width: 100%;
    margin-top: 10px;
  }
  .full_width_black {
    background-color: rgb(40, 40, 40);
    color: white;
  }
  .full_width_black_content {
    max-width: 1200px;
    padding: 20px;
    margin: auto;
  }
  .full_width_black_icon {
    font-size: 120px;
    margin-right: 30px;
    width: 500px;
  }
  .flex_center {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container_previous_services {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    border-radius: 5px;
  }
  .container_previous_services > div {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .count_icon {
    font-size: 50px;
    margin: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  .card {
    background-color: rgb(20, 20, 20);
    text-align: center;
    border-radius: 5px;
    padding: 15px;
  }
  .card > h3 {
    padding: 15px;
    font-size: 20px;
  }
  .swiper_container {
    display: flex;
    justify-content: center;
  }
  .swiper_card {
    background-color: rgb(10, 10, 10);
    padding: 5px;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
  }
  .swiper_card > p {
    text-align: left;
    padding: 10px;
  }
  .icon_container_card {
    padding: 15px;
  }
  .call_container {
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .call_button {
    width: 100%;
    margin-top: 10px;
  }
  .full_width_black {
    background-color: rgb(30, 30, 30);
    color: white;
  }
  .full_width_black_content {
    max-width: 1200px;
    padding: 20px;
    margin: auto;
  }
  .full_width_black_icon {
    font-size: 120px;
    margin-right: 30px;
    width: 500px;
  }
  .flex_center {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container_previous_services {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    border-radius: 5px;
  }
  .container_previous_services > div {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container_previous_services > div > h1 {
    font-size: 24px;
  }
  .container_previous_services > div > p {
    font-size: 12px;
  }
  .count_icon {
    font-size: 30px;
    margin: 20px;
    margin-bottom: 10px;
  }
}
