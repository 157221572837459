#contact_jumbo:after {
  background: url("./assets/jumbo.webp") no-repeat center center;
  filter: brightness(40%);
  background-size: cover;
}

@media only screen and (min-width: 701px) {
  .container {
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    padding: 20px;
  }
  .faq_container {
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
  }
  .container > div {
    width: 50%;
  }
}
@media only screen and (max-width: 700px) {
  .container {
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
  }
  .faq_container {
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
  }

  .container > div {
    width: 100%;
  }
}
