.container {
  text-align: center;
  font: 20px Helvetica, sans-serif;
  color: #000000;
  text-align: left;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: 20px;
}

.container h1 {
  font-size: 46px;
}

a {
  color: #00aced;
  text-decoration: none;
}

a:hover {
  color: #0d4d8b;
  text-decoration: none;
}
