.jumbo {
  background-color: rgb(30, 30, 30);

  margin-top: -150px;
  padding-top: 250px;
  color: white;
}
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
