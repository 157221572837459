@media only screen and (min-width: 701px) {
  .side_menu_mobile_closed,
  .side_menu_mobile {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .side_menu {
    display: none;
  }
  .side_menu_mobile {
    background-color: rgb(20, 20, 20, 0.9);
    height: 220px;
    padding: 15px;
    z-index: 20;
    color: white;
    overflow: hidden;
    transition: 200ms;
  }
  .side_menu_mobile_closed {
    height: 50px;
    background-color: rgb(20, 20, 20, 0.9);
    overflow: hidden;
    padding: 15px;
    z-index: 20;
    color: white;
    overflow: hidden;
    transition: 300ms;
  }

  .mobile_list {
    list-style-type: none;
    padding: 0;
  }
  .mobile_header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .side_menu_item {
    transition: 200ms;
    padding: 5px;
  }
  .side_menu_active_item {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    transition: 200ms;
    padding: 5px;
    border-radius: 5px;
  }
  .content {
    padding: 10px;
    width: 100%;
  }
  .up_arrow {
    transform: rotate(180deg);
    transition: 200ms;
    margin-right: 13px;
  }
  .down_arrow {
    transition: 200ms;
    margin-right: 13px;
  }
}
