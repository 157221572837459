.footer-container {
  width: calc(100%);
  background-color: rgb(20, 20, 20);
  color: white;
  padding: 40px;
  text-align: left;
}
.footer-content-container {
  max-width: 800px;
  margin: auto;
}
.footer-copyright-container {
  text-align: center;
  margin: auto;
  background-color: rgb(10, 10, 10);
  color: white;
  padding: 15px;
}
.footer-copyright-container > p {
  margin: 0;
}
.footer-list-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-logo-container {
  text-align: left;
  display: flex;
}
