#jumbo:after {
  background: url("./assets/jumbo.webp") no-repeat center center;
  filter: brightness(40%);
  background-size: cover;
}

.contact_button {
  width: 100%;
}

@media only screen and (min-width: 701px) {
  .container {
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    padding: 30px;
  }
  .content_container {
    display: flex;
  }
  .side_menu {
    width: 300px;

    top: 200px;
    list-style-type: none;
    padding: 0;
    margin-right: 50px;
  }
  .side_menu > li {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
  }
  .side_menu_item {
    transition: 200ms;
  }
  .side_menu_active_item {
    background-color: #000000;
    color: white;
    transition: 200ms;
    border-radius: 5px;
  }
  .content {
    padding: 10px;
    width: 100%;
  }
  .side_menu_mobile_closed,
  .side_menu_mobile {
    display: none;
  }
  .desktop_sidemenu_container {
    width: 350px;
    position: sticky;
    top: 100px;
    height: fit-content;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    max-width: 1200px;
    margin: auto;
    margin-top: 10px;
    padding: 30px;
    padding-top: 10px;
  }
  .container > h2 {
    display: none;
  }

  .side_menu,
  .desktop_sidemenu_container {
    display: none;
  }
}
