@media only screen and (min-width: 701px) {
  .container_big {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 40px;
    transition: 500ms;
    height: 150px;
    color: white;
  }
  .container_small {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 10px;
    background-color: rgb(20, 20, 20, 0.7);
    backdrop-filter: blur(10px);
    transition: 200ms;
    height: 70px;
    color: white;
  }
  .container_big,
  .container_small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
  }
  .nav_mobile {
    display: none;
  }
  .nav_desktop ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  .nav_desktop > ul > li {
    margin-left: 15px;
    font-size: 18px;
    color: white !important;
  }
  .menu_button {
    display: none;
  }
  .white {
    color: white;
  }
}
@media only screen and (max-width: 700px) {
  .hide_header,
  .show_header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: 90;
    transition: 400ms;
  }
  .hide_header {
    transform: translateY(-70px);
  }

  .container_big {
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 40px;
    transition: 500ms;
    height: 100px;
    color: white;
  }
  .container_small {
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 10px;
    background-color: rgb(20, 20, 20, 0.9);
    transition: 200ms;
    height: 70px;
    color: white;
  }
  .container_big,
  .container_small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
  }

  .nav_desktop ul {
    display: none;
  }

  .nav_mobile {
    position: fixed;
  }

  .nav_mobile ul {
    list-style-type: none;
  }
  .nav_mobile > ul > li > a {
    font-size: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .menu_button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    padding: 10px;
    color: white;
  }
}
