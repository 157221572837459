@media only screen and (max-width: 900px) {
  .logo_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon_container {
    min-width: 50px;
    margin-right: 5px;
  }
  .icon {
    width: 100%;
    font-size: 40px;
    transition: 200ms;
  }
  .icon_small {
    width: 100%;
    font-size: 30px;
    transition: 200ms;
  }

  .text_container h3 {
    font-size: 28px;
    transition: 200ms;
    margin-bottom: -5px;
  }
  .text_container_small {
    margin-left: -10px;
    transition: 200ms;
  }
  .text_container_small h3 {
    font-size: 20px;
    transition: 200ms;
    margin-bottom: -10px;
  }
  .text_container span {
    transition: 200ms;
  }
  .text_container_small span {
    font-size: 12px;
    transition: 200ms;
  }
}

@media only screen and (min-width: 901px) {
  .logo_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon_container {
    min-width: 50px;
    margin-right: 5px;
  }
  .icon {
    width: 100%;
    font-size: 60px;
    transition: 200ms;
  }
  .icon_small {
    width: 100%;
    font-size: 30px;
    transition: 200ms;
  }

  .text_container h3 {
    font-size: 38px;
    transition: 200ms;
  }
  .text_container_small h3 {
    font-size: 20px;
    transition: 200ms;
  }
}
