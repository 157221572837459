#about_jumbo:after {
  background: url("./assets/jumbo.webp") no-repeat center center;
  filter: brightness(40%);
  background-size: cover;
}

@media only screen and (min-width: 701px) {
  .about_container {
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
    padding: 30px;
  }
  .what_we_do {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second,
  .first {
    width: 50%;
    height: 300px;
  }

  .first {
    margin-right: 30px;
  }
  .info_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .center {
    text-align: center;
    margin: auto;
    margin-top: 50px;
  }
  .image_link_container {
    margin: 15px;
    cursor: pointer;
  }

  .certification_image {
    width: 300px;
    height: 200px;
    object-fit: contain;
  }
}
@media only screen and (max-width: 700px) {
  .about_container {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    padding: 15px;
  }
  .what_we_do {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .first {
    display: none;
  }
  .info_image {
    display: none;
  }
  .certification_image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
