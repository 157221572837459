@media only screen and (max-width: 700px) {
  main {
    min-height: 100vh;
    margin-top: 0px;
    /* padding-top: 100px; */
  }
}
@media only screen and (min-width: 701px) {
  main {
    min-height: 100vh;
    margin-top: 150px;
  }
}

.App-link {
  color: #61dafb;
  background-color: none;
  background: none;
  border: none;
}
.App-link:hover {
  opacity: 0.5;
}
a {
  color: #61dafb;
}

.NOT-LOADED {
  opacity: 0;
  transition: 200ms ease-in-out;
}
.LOADED {
  opacity: 1;
  transition: 200ms ease-in-out;
}
