@media only screen and (min-width: 701px) {
  .jumbo {
    position: relative;
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    color: white;
    margin-top: -150px;
    min-height: 500px;
  }
  .jumbo:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .jumbo_content {
    max-width: 1200px;
  }
  .jumbo h1 {
    font-size: 3rem;
    width: 80vw;
  }
  .jumbo p {
    max-width: 700px;
    font-size: 18px;
    font-weight: normal;
  }
}
@media only screen and (max-width: 700px) {
  .jumbo {
    position: relative;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
  }
  .jumbo:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(40%);
    background-size: cover;
  }
  .jumbo_content {
    max-width: 1200px;
  }
  .jumbo h1 {
    font-size: 2rem;
    width: 80%;
  }
}
