#home_jumbo:after {
  background: url("./assets/jumbo.webp") no-repeat center center;
  filter: brightness(40%);
  background-size: cover;
}
@media only screen and (min-width: 701px) {
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
}
